import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import 'rsuite/dist/rsuite.min.css';
import DateRangePicker, { DateRange } from 'rsuite/DateRangePicker';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import { useGetUser } from '@hooks/selectors';
import { getIssuesSummaryData } from '@utils/api';
import { useEffect, useState } from 'react';
import { IssuesSummaryData } from '@types';
import { Box, Typography } from '@mui/material';

const columns: GridColDef[] = [
	{ field: 'id', headerName: 'ID', flex: 0.5 },
	{ field: 'project', headerName: 'Project', flex: 0.6 },
	{
		field: 'issueId',
		headerName: 'Issue ID',
		flex: 0.5,
		renderCell: (params: GridRenderCellParams<{ value: string }>) => (
			<a href={`https://noldor.atlassian.net/browse/${params.value}`} target="_blank" rel="noreferrer">
				{params.value}
			</a>
		),
	},
	{ field: 'components', headerName: 'Components', flex: 0.75 },
	{
		field: 'title',
		headerName: 'Title',
		flex: 1,
	},
	{
		field: 'hours',
		headerName: 'Hours',
		type: 'number',
		flex: 0.25,
	},
];

export const IssueSummary = () => {
	const user = useGetUser();
	const [dateRange, setDateRange] = useState<DateRange>([new Date(), new Date()]);
	const [rows, setRows] = useState<IssuesSummaryData>([]);
	const [total, setTotal] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		updateTable(dateRange);
	}, [dateRange, user]);

	const updateTable = async (range: DateRange) => {
		setIsLoading(true);

		if (!user) {
			return;
		}

		const [startDate, endDate] = range;
		const startDateTS = startDate.getTime();
		const endDateTS = endDate.getTime();
		const data = await getIssuesSummaryData({ startDateTS, endDateTS }, user.accountId);
		setRows(data);
		const sum = data.reduce((acc, row) => {
			return acc + row.hours;
		}, 0);
		setTotal(sum);
		setIsLoading(false);
	};

	const handleDateChange = (range: DateRange | null) => {
		if (range) {
			setDateRange(range);
		}
	};
	return (
		<Box marginTop="100px">
			<Grid container direction="column" alignItems="center" justifyContent="center">
				<Grid container width="70%" justifyContent="end" marginBottom="20px">
					<DateRangePicker value={dateRange} placement="bottomEnd" onChange={handleDateChange} defaultCalendarValue={[new Date(), new Date()]} />
				</Grid>
				<Grid container width="70%">
					<div style={{ height: 500, width: '100%' }}>
						{isLoading ? (
							<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
								<CircularProgress />
							</Box>
						) : (
							<DataGrid
								rows={rows}
								columns={columns}
								autoPageSize
								// rowsPerPageOptions={[5, 10, 20, 50, 100]}
								columnVisibilityModel={{
									id: false,
								}}
							/>
						)}
					</div>
					<Typography>Total hours: {total}</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};
