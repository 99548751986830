import Typography from '@mui/material/Typography';

export const Heading: FCwithChildren = ({ children }) => (
	<Typography variant="h3" component="div" gutterBottom>
		{children}
	</Typography>
);

export const Subtitle: FCwithChildren = ({ children }) => (
	<Typography variant="subtitle1" gutterBottom>
		{children}
	</Typography>
);
