import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthData } from '@types';

export interface GeneralState {
	user?: AuthData;
	isLoading: boolean;
	jwt?: string;
}

const initialState: GeneralState = {
	user: undefined,
	isLoading: false,
	jwt: undefined,
};

export const counterSlice = createSlice({
	name: 'general',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<AuthData>) => {
			state.user = action.payload;
		},
		setIsLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setJwt: (state, action: PayloadAction<string>) => {
			state.jwt = action.payload;
		},
	},
});

export const { setUser, setIsLoading, setJwt } = counterSlice.actions;

export default counterSlice.reducer;
