import Button, { ButtonProps } from '@mui/material/Button';
import { Link } from 'react-router-dom';

interface Props extends ButtonProps {
	path?: string;
	externalLink?: string;
}

export const NButton: FCwithChildren<Props> = ({ children, path, externalLink, ...props }) => {
	const button = (
		<Button color="inherit" {...props}>
			{children}
		</Button>
	);
	if (path) {
		return <Link to={path}>{button}</Link>;
	}
	if (externalLink) {
		return (
			<a href={externalLink} rel="noreferrer">
				{button}
			</a>
		);
	}
	return button;
};
