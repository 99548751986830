import { useState, useEffect } from 'react';

import { NButton, Subtitle } from '@ui-kit';
import { getAtlassianUrl } from '@utils/api';

export const Login: React.FC = () => {
	const [atlassianUrl, setAtlassianUrl] = useState();

	useEffect(() => {
		getAtlassianUrl().then((url) => {
			setAtlassianUrl(url);
		});
	}, []);
	return (
		<>
			<Subtitle>This site uses your Atlassian login for authentication. Authenticate by clicking the button below.</Subtitle>
			<NButton externalLink={atlassianUrl}>Login with Atlassian</NButton>
		</>
	);
};
