import { ComponentType, useState } from 'react';
import { TextField, TextFieldProps, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';

import { Heading, NButton } from '@ui-kit';
import { getCsvData } from '@utils/api';

const QueryField: ComponentType<TextFieldProps> = styled(TextField)`
	width: 50%;
`;

export const ManualQuery: React.FC = () => {
	const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
	const endOfMonth = dayjs().endOf('month').format('YYYY-MM-DD');
	const [value, setValue] = useState(`project = "AM" AND worklogDate >= ${startOfMonth} AND worklogDate <= ${endOfMonth}`);

	const handleDownloadClick = () => {
		getCsvData(value);
	};

	return (
		<Grid container direction="column" alignItems="center" justifyContent="center" marginTop="100px">
			<Heading>Manual Query</Heading>
			<QueryField id="outlined-multiline-flexible" label="Query" multiline maxRows={4} value={value} onChange={(event) => setValue(event.target.value)} />
			<NButton variant="contained" color="secondary" sx={{ marginTop: '30px' }} onClick={handleDownloadClick}>
				Download
			</NButton>
		</Grid>
	);
};
