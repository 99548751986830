import { useState, useEffect } from 'react';
import { TextField, Grid, MenuItem, Alert } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import DateRangePicker from 'rsuite/DateRangePicker';

import { Heading, NButton } from '@ui-kit';
import { getCsvData, getUsers } from '@utils/api';

interface User {
	accountId: string;
	displayName: string;
}

export const Users: React.FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [users, setUsers] = useState<User[]>([]);
	const [getUsersError, setGetUsersError] = useState<unknown>();
	const [selectedUser, setSelectedUser] = useState('');
	const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('month'));
	const [endDate, setEndDate] = useState<Dayjs>(dayjs().endOf('month'));

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const usersList = await getUsers();
				setUsers(usersList);
			} catch (error) {
				setGetUsersError(error);
			}
			setIsLoading(false);
		};
		fetchUsers();
	}, []);

	const handleDownload = () => {
		const query = `worklogDate >= ${startDate.format('YYYY-MM-DD')} AND worklogDate <= ${endDate.format('YYYY-MM-DD')}${
			selectedUser ? ` AND worklogAuthor = ${selectedUser}` : ''
		}`;
		getCsvData(query);
	};

	return (
		<Grid container direction="column" alignItems="center" justifyContent="center" marginTop="100px" spacing={3}>
			<Grid item>
				<Heading>User Query </Heading>
			</Grid>
			<Grid item sx={{ width: '50%' }}>
				<TextField
					disabled={isLoading || !!getUsersError}
					select
					fullWidth
					label={isLoading ? 'Loading...' : 'Select User'}
					value={selectedUser}
					onChange={(e) => setSelectedUser(e.target.value)}
				>
					{users.map((user) => (
						<MenuItem key={user.accountId} value={user.accountId}>
							{user.displayName}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			{!!getUsersError && (
				<Grid item sx={{ width: '50%' }}>
					<Alert severity="error">
						<pre>
							<code>{JSON.stringify(getUsersError, null, 4)}</code>
						</pre>
					</Alert>
				</Grid>
			)}
			<Grid item sx={{ width: '50%' }}>
				<DateRangePicker
					format="yyyy-MM-dd"
					value={[startDate.toDate(), endDate.toDate()]}
					onChange={(newValue) => {
						if (newValue) {
							setStartDate(dayjs(newValue[0]));
							setEndDate(dayjs(newValue[1]));
						}
					}}
					style={{ width: '100%' }}
				/>
			</Grid>
			<Grid item>
				<NButton variant="contained" color="secondary" onClick={handleDownload}>
					Download
				</NButton>
			</Grid>
		</Grid>
	);
};
