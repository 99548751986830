import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { useGetJwt } from '@hooks/selectors';
import { setIsLoading, setJwt } from '@slices/general';
import { getValidUser } from '@utils/api';

export const Protected: FCwithChildren = ({ children }) => {
	const dispatch = useDispatch();
	const jwt = useGetJwt();

	const navigate = useNavigate();

	const getUserAndDecideRender = async () => {
		dispatch(setIsLoading(true));
		const success = await getValidUser();
		dispatch(setIsLoading(false));
		if (!success) {
			navigate('/login');
		}
	};

	useEffect(() => {
		if (!jwt) {
			const lsJwt = localStorage.getItem('jwt');
			if (lsJwt) {
				axios.defaults.headers.authorization = lsJwt;
				dispatch(setJwt(lsJwt));
			}
		}
		getUserAndDecideRender();
	}, []);

	return children;
};
