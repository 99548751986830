import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';

import { sendAtlassianCode } from '@utils/api';

export const ParseCode: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (!location) {
			return;
		}
		const parsedQS = qs.parse(location.search) as { code: string };
		sendAtlassianCode(parsedQS.code).then(() => navigate('/'));
	}, [location, navigate]);

	return <div>Please wait</div>;
};
